import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchBreadcumb, fetchPageProps } from '../../../Redux'
import BreadcrumCmnt from '../../BreadcrumCmnt'
import Presentations from './Products/Presentations'

import { Select, Switch, Popconfirm, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import 'antd/lib/switch/style/css'
import 'antd/lib/button/style/css'
import { useState } from 'react'
import { ProductItemOptionsContainer } from './Styles/Styles'
import { AddProductContainer } from './AddProduct/Styles/styles'
import { host, dir_api_name } from '../../../api_vars'

const { Option } = Select;

export default function AddProduct() {
    const dispatch = useDispatch()

    const defaultPresentation = [
        { "name": "Porcionado 5xLb", "selectable": true },
        { "name": "Porcionado 4xLb", "selectable": true },
        { "name": "Porcionado 3xLb", "selectable": true },
        { "name": "Porcionado 2xLb", "selectable": true },
        { "name": "Entero", "selectable": true },
        { "name": "Molido", "selectable": true },
        { "name": "Mariposa", "selectable": true },
        { "name": "Goulash", "selectable": true },
    ]
    const productInitialState = {
        name: "",
        price: "",
        line: "CERDO",
        unit: "LIBRA",
        presentations: defaultPresentation,
        status: "true"
    }

    const [product, setProduct] = useState(productInitialState)

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);

    const myInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
    };


    const [idLetter, setidLetter] = useState("C")
    const [idNumber, setIdNumber] = useState("000")

    const updatePresentation = (meetName = "", indexProduct = "", PresentationsList = "", itemId = "") => {
        setProduct({ ...product, presentations: [...PresentationsList] })
    }

    const updateMeetType = (value) => {
        setProduct({ ...product, line: value })
        if (value === "CERDO") {
            setidLetter("C")
        } else if (value === "RES") {
            setidLetter("R")
        } else if (value === "POLLO") {
            setidLetter("P")
        } else if (value === "PESCADOS Y MARISCOS") {
            setidLetter("M")
        } else if (value === "CARNES FRIAS") {
            setidLetter("F")
        } else if (value === "ANTOJOS") {
            setidLetter("A")
        }
    }

    const toggleBtnStatus = (option) => {
        const btn = document.getElementById("Add")

        switch (option) {
            case "Active":
                btn.className += " actived"
                btn.dataset.status = "actived"
                break;
            case "Disabled":
                btn.className = "update_product btn_product_controls"
                btn.dataset.status = "disabled"
                break;
            default:
                break;
        }
    }

    const AddProduct = (e) => {
        const btn = document.getElementById("Add")

        const btnText = document.getElementById("Update_text")

        btnText.innerText = "Añadiendo"

        if (btn.dataset.status === "actived") {
            const query = `?name=${product.name}&plu=${idLetter + idNumber}&price=${product.price}&line=${product.line}&unit=${product.unit}&presentations=${encodeURI(JSON.stringify(product.presentations))}&status=${product.status}`
            fetch(host + dir_api_name + '/api_add_product.php' + query, myInit).then((response) => {
                if (response.ok) return response.text()

                else { throw response; };

            })

                .catch(err => {
                    console.log(err)
                    btnText.innerText = "Error al Añadiendir"
                })
                .then((response) => {

                    setProduct({ ...productInitialState })
                    btnText.innerText = "Añadido..."
                    setTimeout((() =>
                        btnText.innerText = "Añadir producto"
                    ), 2000)
                    toggleBtnStatus("Disabled")
                    consultId()

                })
        }
    }


    const consultId = () => {

        fetch(host + dir_api_name + '/api_consult_id.php', myInit).then((response) => {
            if (response.ok) return response.text()

            else { throw response; };

        })
            .catch(err => console.log(err))
            .then((response) => {
                setIdNumber(response)
            })

    }

    useEffect(() => {
        let breadCrumb = [
            { title: "Productos", link: "/admin/add_product/", disabled: true },
            { title: "Añadir producto", link: "/admin/add_product/", disabled: false },
        ]
        dispatch(fetchBreadcumb(breadCrumb))
        consultId()
    }, [])

    useEffect(() => {

        const btn = document.getElementById("Add")

        if (product.name !== "" && product.price !== "") {
            if (btn.className !== "update_product btn_product_controls active") {
                toggleBtnStatus("Active")
            }
        } else {
            toggleBtnStatus("Disabled")
        }
    }, [product])

    useEffect(() => {

        dispatch(fetchPageProps({ titlePage: "Añadir Producto" }))

    }, [])

    return (
        <div>
            <BreadcrumCmnt stylesIncome={{ marginTop: "40px" }} />
            <AddProductContainer>
                <div className="Form-body-section1-food-body-item">
                    <div className="item-section1 flex">

                        <p>{idLetter + idNumber + " -"} </p>

                        <input type="text" className="input_style1" required id="product_name" placeholder="Nombre" value={unescape(product.name)} onChange={(e) => setProduct({ ...product, name: escape(e.target.value) })} />

                        <p>
                            &nbsp;- <b>$ &nbsp;</b>
                        </p>
                        <input type="number" className="Quantities" required name="price" id="price" min="0" placeholder="Precio" value={product.price} onChange={(e) => setProduct({ ...product, price: e.target.value })} />

                        <Select id="unit" defaultValue="CERDO" style={{ width: 180, marginLeft: "5px" }} onChange={(e) => updateMeetType(e)}>
                            <Option value="CERDO">CERDO</Option>
                            <Option value="RES">RES</Option>
                            <Option value="POLLO">POLLO</Option>
                            <Option value="PESCADOS Y MARISCOS">PESCADOS Y MARISCOS</Option>
                            <Option value="CARNES FRIAS">CARNES FRIAS</Option>
                            <Option value="ANTOJOS">ANTOJOS</Option>
                        </Select>

                        <Select id="unit" defaultValue="LIBRA" style={{ width: 120, marginLeft: "5px" }} onChange={(e) => setProduct({ ...product, unit: e })}>
                            <Option value="LIBRA">LIBRA</Option>
                            <Option value="UNIDAD">UNIDAD</Option>
                        </Select>

                        <Presentations
                            key={1}
                            presentations={null}
                            updatePresentation={updatePresentation}
                            itemId={1}
                            meetName={"none"}
                            indexProduct={1}
                        />
                        <Switch checkedChildren="Activo" unCheckedChildren="Desactivado" checked={product.status} id={"Status"} style={{ marginLeft: "auto" }}
                            onChange={(e) => setProduct({ ...product, status: e })} />

                    </div>

                    <div className="item-section2">

                    </div>

                </div>
                <ProductItemOptionsContainer className="flex" style={{ justifyContent: "flex-end", marginTop: "2vh" }}>
                    <button id={"Add"} className="update_product btn_product_controls" data-status="disabled"
                        onClick={() => AddProduct()}>
                        <PlusOutlined style={{ fontSize: "16px" }} />
                        <span id={"Update_text"}>Añadir producto</span>
                    </button>
                </ProductItemOptionsContainer>
            </AddProductContainer>

        </div>
    )
}
