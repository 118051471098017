//Test Online mod

// Local Host Mode
// const host = "http://localhost/"
// const dir_api_name = "API"
// const testMode = true
// const appVersion = "beta 1.2.9"


const testMode = true
const developerMode = true
let host
let dir_api_name
let appVersion

if (testMode && developerMode) {
    host = "https://testadmin.santascarnitas.com/"
    dir_api_name = "API"
    appVersion = "4.05b"
} if (developerMode) {
    host = "https://testadmin.santascarnitas.com/"
    dir_api_name = "API"
    appVersion = "4.05b"
}
else {
    host = "https://admin.santascarnitas.com/"
    dir_api_name = "APIv4.2"
    appVersion = "4.05b"
}
//Currency variables

const currencyCountry = "de-AT"
const styleCurrency = { style: "currency", currency: "USD" }


export { host, dir_api_name, testMode, appVersion, currencyCountry, styleCurrency }