import React, { useState, useEffect } from 'react'
import { OrderState } from './../../Style/Styles'
import { Steps, Popconfirm } from 'antd'
import { checkUserAccess } from '../../../../../Tools/checkUserLevel';

import 'antd/lib/message/style/css'
import { testMode } from '../../../../../../api_vars';

const { Step } = Steps

export default function OrderSteps({ orderStatusProp, setOrderData, orderData, setNewOrderState }) {

    const [access, setAccess] = useState({
        confirmed: false,
        portioned: false,
        dispatched: false
    })

    useEffect(() => {


        if (testMode)

            setAccess({
                confirmed: true,
                portioned: true,
                dispatched: true
            })

        else

            checkUserAccess(["1", "3"])
                .then(res => {

                    const confirmed = res


                    checkUserAccess(["2", "3"]).then((res) =>
                        setAccess({
                            confirmed: confirmed,
                            portioned: res,
                            dispatched: res
                        })
                    )
                })
    }, [])

    const PopUp = ({ title, orderNumber }) =>
        <Popconfirm placement="top" title={`¿Desea establecer este pedido como ${title}?`} onConfirm={() => { setNewOrderState(orderNumber) }} okText="Si" cancelText="No" onCancel={(e) => { console.log(e) }}>
            <span style={{ cursor: "pointer" }}>{title}</span>
        </Popconfirm>

    return (
        <OrderState>
            <Steps current={orderStatusProp} direction="vertical" size="small" status="process" onChange={null}>

                <Step //Sin confirmar
                    title={
                        "Sin Confirmar"
                    } disabled={orderStatusProp < 1 ? false : true} />

                <Step //Cancelado
                    title={
                        orderStatusProp < 4 && orderStatusProp !== 1 ?

                            <PopUp title={orderStatusProp === 1 ? "Cancelado" : "Cancelar"} orderNumber={1} />

                            : "Cancelar"

                    } id="orderCanceled" className={"orderCanceled"} disabled={orderStatusProp < 4 && orderStatusProp !== 1 ? false : true} />

                <Step //Confirmado
                    title={
                        orderStatusProp < 3 && orderStatusProp !== 1 && access.confirmed ? <PopUp title={orderStatusProp === 2 ? "Confirmado" : "Confirmar"} orderNumber={2} /> : "Confirmar"
                    }
                    className="orderConfirmed" disabled={orderStatusProp < 3 && orderStatusProp !== 1 ? false : true} />

                <Step //Porcionado
                    title={
                        orderStatusProp < 4 && orderStatusProp !== 1 && access.portioned ? <PopUp title="Porcionado" orderNumber={3} /> : "Porcionado"
                    }
                    className="orderPortioned" disabled={orderStatusProp < 4 && orderStatusProp !== 1 ? false : true} />

                <Step //Despachado
                    title={
                        orderStatusProp < 4 && orderStatusProp !== 4 && orderStatusProp !== 1 && access.dispatched ? <PopUp title="Despachado" orderNumber={4} /> : "Despachado"
                    } className="orderDispatched" disabled={orderStatusProp < 4 && orderStatusProp !== 4 && orderStatusProp !== 1 ? false : true} />

                <Step //Despachado
                    title={
                        orderStatusProp === 4 && orderStatusProp !== 5 ? <PopUp title={"Entregado"} orderNumber={5} /> : "Entregado "
                    } className="orderDispatched" disabled={orderStatusProp === 4 ? false : true} />
            </Steps>
        </OrderState>

    )
}
