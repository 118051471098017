import React, { useState, useEffect } from 'react'

import { Link, useParams } from 'react-router-dom'
import { host, dir_api_name, testMode } from '../../../../api_vars'

import 'antd/lib/notification/style/css'
import 'antd/lib/alert/style/css'

import { Button, notification, Collapse, Alert, InputNumber, Input, Divider } from 'antd'
import { NotesContainer } from './Styles/Styles'
import CartComponent from './Cart/Cart'
import BreadcrumCmnt from '../../../BreadcrumCmnt'
import { useDispatch } from 'react-redux'
import { fetchBreadcumb, fetchOrderData, fetchLoadingStatus, fetchPageProps } from '../../../../Redux'
import { FileTextOutlined, ShoppingOutlined } from '@ant-design/icons';
import Address from './Address/Address'
import { checkUserAccess } from '../../../Tools/checkUserLevel'
import { checkRol } from '../../../Util/checkRol'
import Chip from '@material-ui/core/Chip';
const { Panel } = Collapse;
const panel_width = window.screen.width <= 600 ? 98 + "vw" : 51 + "vw";


/**
 * @function fetchData - Fetch the data of order
 * @function RegisterUserData - set User data of the form
 * @function checkIdEncription - check if id is encripted
 */


export default function API_Cart() {

  const [rol, setRol] = useState(testMode ? "3" : 0)

  const { id, route_id, user_id } = useParams()
  const [idState, setIdState] = useState(id)
  const [Cart, setCart] = useState({
    promotions: [],
    pig: [],
    beef: [],
    chicken: [],
    fish: [],
    sausages: [],
    cravings: [],
    combos: [],
    search: [],
    notes: "",
    notesAsesor: ""
  })
  const [total, setTotal] = useState(0)
  const [newTotal, setNewTotal] = useState(0)
  const [invoiceNumber, setInvoiceNumber] = useState(0)
  const dispatch = useDispatch()


  const [userData, setUserData] = useState({
    name: "",
    last_name: "",
    id: "",
    cell: "",
    email: "",
    tel: "",
    address: "",
    city: "",
    street: "",
    point_reference: "",
    date: "",
    hour: "",
    minute: "",
    am_pm: "",
    payment_method: "",
    orderStatus: ""
  })

  const [PanelsStatus, setPanelsStatus] = useState({
    data_user: {
      show: window.screen.width <= 600 ? true : false
    },
    order: {
      show: window.screen.width <= 600 ? false : true
    },
  })

  const [newUserData, setNewUserData] = useState({})

  const [user, setUser] = useState({
    promotions_used: 0,
    userId: 0
  })

  const RegisterUserData = (e, name) => {


    let value = name === "orderStatus" ? e : e.target.value

    setNewUserData({
      ...newUserData,
      [`${name}`]: "" + value + ""
    })


  }
  const openNotification = (title, type) => {
    let message
    const notificationSucess = (message) => {
      notification.success({
        message: message,
        description: title,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        placement: "bottomRight",
        className: "notifications"
      });
    }

    const notificationDeleted = (message) => {
      notification.error({
        message: message,
        description: title,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        placement: "bottomRight",
        className: "notifications"
      })
    }



    switch (type) {
      case "actualizando":
        message = "Actualizando pedido"
        notificationSucess(message)
        break;

      case "actualizado":
        message = "Actualizado!"
        notificationSucess(message)
        break;
      case "enviando":
        message = "Enviando email!"
        notificationSucess(message)
        break;
      case "enviado":
        message = "Email enviado !"
        notificationSucess(message)
        break;

      case "error":
        message = "Error!"
        notificationDeleted(message)
        break;

      default:
        return false;
    }


  };

  const checkIdEncription = (id) => {
    return isNaN(id) ? true : false
  }



  const sendEmail = (type) => {
    let headers = new Headers();
    let miInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    };
    let link = escape(`https://precompra.santascarnitas.com/Registro/`)
    let email = escape(newUserData.email)

    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);

    openNotification("Enviado email", "enviando")

    let name = userData.name + " " + userData.last_name
    let totalOrder = Number(newTotal !== 0 ? newTotal : total).toLocaleString("de-AT", { style: "currency", currency: "USD" })

    return fetch(`${host}${dir_api_name}/api_sendEmail.php?email=${email}&link=${link}&id=${idState}&type=${type}&date=${userData.date}&name=${name}&total=${totalOrder}`, miInit).then((response) => {

      if (response.ok) {
        return response.text()
      }
      else { throw response };

    }).catch(err => {
      openNotification(`Error enviando email ref: ${err}`, "error")
    })
  }

  async function update_order(e) {

    let btnUpdate = e.target

    btnUpdate.disabled = true;

    openNotification("Actualizando...", "actualizando")

    let status = true

    if (status) {

      const end_total = Number(userData.orderStatus) === 5 || newTotal === 0 && Number(userData.orderStatus) !== 2 ? total : newTotal;

      let varName = ""

      const idR = id

      if (isNaN(idR)) varName = "key"; else varName = "orderId"

      // alert(varName)

      const sendData = () => {

      const headersOrder = new Headers();
      headersOrder.append('Content-Type', 'application/x-www-form-urlencoded');
      headersOrder.append('Origin', host);
      const body = `${varName}=${idR}
      &order=${escape(JSON.stringify(Cart))}
      &user_data=${escape(JSON.stringify(newUserData))}
      &total=${end_total}
      &delivery_route_id=0
      &position=0`;

      const miInit = {
          method: 'POST',
          headers: headersOrder,
          body: body,
          mode: 'cors'
      };

        return fetch(`${host}${dir_api_name}/api_update_order.php`, miInit).then((response) => {
          if (response.ok) return response.text()

          else { throw response };

        })
          .then((response) => {

            fetch(`${host}${dir_api_name}/api_checkAndUpdateLimit.php?date=${newUserData.date}&id=${idState}`, miInit).then((response) => {
              if (response.ok) return response.text()

              else { throw response };

            })
              .catch(err => {

                openNotification("Error", "error")

                btnUpdate.disabled = false;


              })
            openNotification("Pedido actualizado", "actualizado")
            if (newUserData.orderStatus === "2") {
              setUserData({ ...userData, ...newUserData })
              sendEmail("confirmed").then((response) =>
                openNotification("Email enviado", "enviado")
              )
            }
            else if (newUserData.orderStatus === "3") {
              sendEmail("portioned").then((response) =>
                openNotification("Email enviado", "enviado")
              )
            } else if (newUserData.orderStatus === "4") {
              sendEmail("dispatched").then((response) =>
                openNotification("Email enviado", "enviado")
              )
            }

            btnUpdate.disabled = false;

          }).catch(err => {

            openNotification("Error", "error")

            btnUpdate.disabled = false;

          })
      }

      sendData()

    }

  }

  const selectPortioned = (e) => {

    let value = e.target.value

    if (Number(value) === 3 || Number(value) === 4) {
      if (Number(userData.orderStatus) < 3 || userData.orderStatus === undefined) {

        if (newTotal === 0) {
          alert("Por favor establezca el total")
          document.getElementById("orderStatus").value = userData.orderStatus === undefined ? 0 : userData.orderStatus

        }
        else if (invoiceNumber === 0) {

          alert("Por favor establezca el N° de factura")

          document.getElementById("inputInvoiceNumber").focus()

          document.getElementById("orderStatus").value = userData.orderStatus === undefined ? 0 : userData.orderStatus

        }

        else {

          RegisterUserData(value, "orderStatus");

          return true

        }

      }

    } else {

      RegisterUserData(value, "orderStatus");

    }

  }

  const genExtra = () => (
    <FileTextOutlined
      onClick={event => {
        event.stopPropagation();
      }}
    />
  );

  const orderIcon = () => (
    <ShoppingOutlined
      onClick={event => {
        event.stopPropagation();
      }}
    />
  )


  const fetchData = (type) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);
    const miInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    };

    // let idR = decodeURI(id)
    let varName = checkIdEncription ? "key" : "orderId"

    // idR = idR.replace("°", "+")
    // idR = encodeURI(idR)

    // alert(idR)


    if (isNaN(id)) varName = "key"; else varName = "orderId"

    return fetch(`${host}${dir_api_name}/api_consult_order.php?${varName}=${id}&type=${type}`, miInit).then((response) => {

      if (response.ok) {
        if (type !== "total" && type !== "id" && type !== "user_id") return response.json(); else { return response.text() }
      }

      else { throw response };

    }).catch(err => {
      openNotification(`Error cargando datos ref: ${err}`, "error")
    })




  }

  useEffect(() => {

    dispatch(fetchLoadingStatus({ loading: true, progress: 0 }))
    dispatch(fetchPageProps({ titlePage: "Pedido " + id }))

    fetchData("Order").then((response) => {

      response = JSON.parse(`${response}`)

      setCart({
        ...Cart,
        ...(response)
      })


    }).then(

      fetchData("user_data").then((response) => {

        response = (JSON.parse(`${response}`))

        setUserData((response))
        setNewUserData((response))

        dispatch(fetchOrderData(response))

      }).then(() => {

        fetchData("total").then((response) => {

          dispatch(fetchLoadingStatus({ loading: true, progress: 100 }))


          setTimeout(() => {

            dispatch(fetchLoadingStatus({ loading: false, progress: 0 }))

          }, 400)

          setTotal(response);

          if (userData.orderStatus >= "4") {
            setNewTotal(response)
          }

          fetchData("user_id").then((res) => {
            console.log(res)

            const user_info = JSON.parse(res)
            setUser({ userId: user_info.user_id, promotions_used: user_info.promotions_used })
          })

        }).then(

          checkIdEncription(id) ? fetchData("id").then((response) => {

            setIdState(response)

            // setNewId(Number(id)+1)

          }) :
            null

        )

      })

    )

    const breadCrumb = [{
      title: "Compras",
      link: "/admin/registro/" + id,
      disabled: true
    },
    {
      title: route_id !== undefined ? "Ruta" : "Tabla de pedidos",
      link: route_id !== undefined ? `/admin/delivery_route/${user_id}/${route_id}/` : "/admin/table_orders/1",
      disabled: false
    },
    {
      title: "Pedido " + id,
      link: "/admin/registro/" + id,
      disabled: false
    },
    ]

    dispatch(fetchBreadcumb(breadCrumb))

    checkRol().then((res) => {
      if (!testMode) { setRol(res) }
      else setRol("3");
    })

  }, [])


  useEffect(() => {

    document.getElementById("notesAsesor").value = unescape(Cart.notesAsesor)

  }, [Cart]) // Set the date in input when the data is loaded


  return (
    <div className="Form">

      <BreadcrumCmnt stylesIncome={{ marginTop: "40px" }} />

      {
        window.screen.width <= 700 ?
          <div className="back_btn flex">
            <Link to={`/admin/delivery_route/${user_id}/${route_id}/`}>
              <Button type="primary">
                Volver
        </Button>
            </Link>
          </div>
          : null
      }

      {/* <Link to={"/admin/registro/"+newId} id="link"></Link> */}

      <section className="Form-body Form-API_Cart">

        <Collapse
          expandIconPosition={"right"}
          className="filtrer-collapse-custom-collapse"
          defaultActiveKey={window.screen.width <= 600 ? ['1'] : null}
          onChange={
            () => setPanelsStatus({
              ...PanelsStatus, data_user: {
                ...PanelsStatus.data_user,
                show: !PanelsStatus.data_user.show
              }
            })
          }
          style={{
            width: panel_width,
            height: PanelsStatus.data_user.show ? "auto" : "49px"
          }}
        >

          <Panel header="Datos del pedido" key="1" extra={genExtra()}>

            <div id="stage-1" className="Form-body-section2 Form-API_Cart-section1" data-stage="1" style={{ textAlign: "left" }}>

              <form>

                <div className="item">
                  <h3>
                    {
                      user.userId > 0 ?
                        "Usuario registrado"
                        : null
                    }
                  </h3>
                  {
                      newUserData.commune === undefined && Cart.delivery_price === undefined ?
                        <Alert
                          message="Precaución"
                          description={<p>Este pedido corresponde a una versión anterior. Por lo tanto los campos: <b>Comuna</b> y <b>Valor domicilio</b>, no estarán definidos.</p>}
                          type="warning"
                          showIcon
                        />
                        : null
                    }
                  <div className="flex" style={{justifyContent: "flex-start", columnGap: "5px"}}>

                    {
                      user.promotions_used < 2 ?
                        <Chip label="Envío gratis" size="small" style={{backgroundColor: "#64dd17", color: "white"}} />
                        : null
                    }
                    {userData.dispatch_option === "pick_order" ?
                      <Chip label="Recorger en punto de venta" size="small" style={{ backgroundColor: "#40a9ff", color: "white" }} />
                      : null
                    }
                  </div>
                </div>

                <div className="flex"
                // ID and State
                >
                  <div>
                    <label>ID</label>
                    <input type="text" id="userData-idOrder" readOnly value={idState} />
                  </div>
                  <div>
                    <label>Estado</label>
                    <select id="orderStatus" onInput={(e) => { selectPortioned(e) }} value={newUserData.orderStatus}>

                      <option value="">

                        {
                          userData.orderStatus === undefined || userData.orderStatus === 0 ? "Sin Cofirmar" :
                            userData.orderStatus === "1" ? "Cancelado" :
                              userData.orderStatus === "2" ? "Confirmado" :
                                userData.orderStatus === "3" ? "Porcionado" :
                                  userData.orderStatus === "4" ? "Despachado" : "Error"
                        }

                      </option>

                      <option value="" disabled> --------- </option>
                      {
                        checkUserAccess(["1", "3"]) ?
                          <option value="0" disabled={userData.orderStatus < "1" || userData.orderStatus === undefined ? false : true}> Sin Cofirmar </option> :
                          <option value="0" disabled> Sin Cofirmar </option>
                      }
                      {
                        checkUserAccess(["1", "3"]) ?
                          <option value="1" disabled={userData.orderStatus < "4" || userData.orderStatus === undefined ? false : true}> Cancelado </option> :
                          <option value="1" disabled> Cancelado </option>
                      }
                      {
                        checkUserAccess(["1", "3"]) ?
                          <option value="2" disabled={userData.orderStatus < "2" || userData.orderStatus === undefined ? false : true}> Confirmado </option> :
                          <option value="2" disabled> Confirmado </option>}

                      {

                        checkUserAccess(["2", "3"]) ?
                          <option value="3" disabled={userData.orderStatus < "3" || userData.orderStatus === undefined ? false : true}> Porcionado </option> :
                          <option value="3" disabled> Porcionado </option>

                      }

                      {

                        checkUserAccess(["2", "3"]) ?
                          <option value="4" disabled={userData.orderStatus < "4" || userData.orderStatus === undefined ? false : true}> Despachado </option> :
                          <option value="4" disabled> Despachado </option>

                      }
                      {

                        checkUserAccess(["0", "2", "3"]) ?
                          <option value="5" disabled={userData.orderStatus < "4" || userData.orderStatus === undefined ? true : false}> Entregado </option> :
                          <option value="5" disabled> Entregado </option>

                      }
                    </select>
                  </div>
                </div>
                <div className="flex"
                // N° Order and delivery man
                >

                  <div>
                    <label>N° Factura</label>
                    <InputNumber
                      defaultValue={newUserData.invoice_number === undefined ? 0 : newUserData.invoice_number}
                      formatter={value => `N° ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                      parser={value => value.replace(/N°\s?|(\.*)/g, '')}
                      style={{ width: "100%" }}
                      // value={Number(invoiceNumber > 0 ? invoiceNumber : newUserData.invoice_number === undefined ? 0 : newUserData.invoice_number)}
                      onChange={(e) => {
                        setNewUserData({
                          ...newUserData,
                          invoice_number: e === null ? 0 : e
                        })
                        setInvoiceNumber(e === null ? 0 : e)
                      }
                      }
                      id="inputInvoiceNumber"
                    />
                  </div>

                  {/* <div>
                    <label>Domiciliario</label>
                    <Input value={newUserData.delivery_man} disabled>

                    </Input>
                  </div> */}

                </div>
                <div className="flex">

                  <div>

                    <label>Nombre</label>

                    <input className="itemsForm" disabled type="text" onInput={(e) => setUserData({ ...userData, name: escape(e.target.value) })} value={unescape(userData.name)} id="userData-name" />

                  </div>

                  <div>

                    <label>Apellido</label>

                    <input type="text" disabled className="itemsForm" value={unescape(userData.last_name)} id="userData-last_name" onInput={(e) => setUserData({ ...userData, last_name: escape(e.target.value) })} />

                  </div>

                </div>

                <div className="flex">


                  <div>

                    <label>Cedula</label>

                    <input type="text" disabled className="itemsForm" id="userData-id" value={unescape(userData.id)} onInput={(e) => setUserData({ ...userData, id: escape(e.target.value) })} />

                  </div>

                  <div>

                    <label>Correo Electrónico</label>

                    <input type="email" disabled id="userData-cell" value={unescape(userData.email)} onInput={(e) => setUserData({ ...userData, email: escape(e.target.value) })} />

                  </div>

                </div>


                <div className="item">

                  <label>Celular</label>

                  <input type="text" id="userData-cell" className="itemsForm" value={unescape(userData.cell)} onInput={(e) => setUserData({ ...userData, cell: escape(e.target.value) })} />

                </div>

                <div className="item">

                  <label>Telefono fijo</label>

                  <input type="text" className="itemsForm" id="userData-tel" value={unescape(userData.tel)} onInput={(e) => setUserData({ ...userData, tel: escape(e.target.value) })} />

                </div>

                <div className="item">

                  <label>Dirección</label>

                  <input type="text" className="itemsForm" id="userData-address" value={unescape(userData.address)} onInput={(e) => setUserData({ ...userData, address: escape(e.target.value) })} />

                </div>

                {
                  <div>


                    <div className="flex">

                      <div>

                        <label>Municipio</label>

                        <input className="itemsForm" type="text" onInput={(e) => setNewUserData({ ...newUserData, city: escape(e.target.value) })} value={(unescape(newUserData.city))} id="userData-city" />

                      </div>

                      <div>

                        <label>Barrio</label>

                        <input type="text" className="itemsForm" value={newUserData.street === undefined ? (unescape(newUserData.neighborhood)) : (unescape(newUserData.street))} id="userData-street"
                          onInput={(e) => {
                            setNewUserData({ ...newUserData, neighborhood: escape(e.target.value), street: undefined })
                          }} />

                      </div>

                    </div>
                    <div className="item">

                      <label>Comuna</label>

                      <input type="text" className="itemsForm" value={newUserData.commune !== undefined ? (unescape(newUserData.commune)) : "No definido"} id="userData-street" onInput={(e) => setNewUserData({ ...newUserData, commune: escape(e.target.value) })} />

                    </div>

                    <div className="item">

                      <label>Cambiar {newUserData.commune !== undefined ? "" : " a la nueva versión:"}</label>

                      <Address setNewUserData={setNewUserData} newUserData={newUserData} setCart={setCart} />

                    </div>

                  </div>
                }

                <div className="item">

                  <label>Punto de referencia</label>

                  <input className="itemsForm" type="text" id="userData-point_reference" value={unescape(userData.point_reference)} onInput={(e) => {
                    if (e.which === 13 && !e.shiftKey) {
                      e.preventDefault();
                      console.log('prevented');
                      return false;
                    } else {
                      setUserData({ ...userData, point_reference: escape(e.target.value) })
                    }
                  }} />

                </div>

                <div className="item">

                  <label>¿Cuando quieres que te llegue?</label>

                  <input className="itemsForm" type="date" id="userData-date" value={newUserData.date} onInput={(e) => setNewUserData({ ...newUserData, date: escape(e.target.value) })} />

                </div>

                <div>

                  <label>¿Como quieres pagar?</label>

                  <select id="userData-payment_method" onInput={(e) => setUserData({ ...userData, payment_method: escape(e.target.value) })}>

                    <option value=""> {unescape(userData.payment_method)} </option>
                    <option value="">  </option>
                    <option value="Efectivo"> Efectivo </option>
                    <option value="Transferencia Electronica"> Transferencia Electrónica </option>
                    <option value="Tarjeta de Crédito o Débito"> Transferencia Electrónica </option>
                    <option value="Código QR"> Código QR </option>

                  </select>

                </div>


              </form>

            </div>

          </Panel>

        </Collapse>
        <Divider className="divider" />
        <Collapse
          defaultActiveKey={window.screen.width <= 600 ? null : ['2']}
          expandIconPosition={"right"}
          className="filtrer-collapse-custom-collapse"
          onChange={
            () => setPanelsStatus({
              ...PanelsStatus, order: {
                ...PanelsStatus.order,
                show: !PanelsStatus.order.show
              }
            })
          }
          accordion={true}
          style={{
            width: panel_width,
            height: PanelsStatus.order.show ? "auto" : "49px",
            marginLeft: window.screen.width <= 600 ? "" : "10px",
            overflow: "hidden"
          }}
        >

          <Panel header="Pedido" key="2" extra={orderIcon()} id="PanelOrder">

            <section className="Form-footer" style={{ textAlign: "left" }}>

              <section className="Form-footer-cart">

                <CartComponent setNewTotal={setNewTotal} Cart={Cart} setCart={setCart} total={total} setTotal={setTotal} rol={rol} />

              </section>

            </section>

          </Panel>

        </Collapse>

      </section>
      <Divider className="divider" />
      <section className="notes flex" style={{ justifyContent: "flex-start", margin: "auto", width: "92vw" }}>

        <NotesContainer>

          <label>Notas Asesor</label>

          <textarea id="notesAsesor" onInput={e => { setCart({ ...Cart, notesAsesor: escape(e.target.value) }) }}
            disabled={rol === "1" || rol === "3" ? false : true}
          ></textarea>

        </NotesContainer>

        <NotesContainer>

          <label>Notas Logistica</label>

          <textarea id="notes" onInput={e => { setCart({ ...Cart, notes: escape(e.target.value) }) }}
            value={unescape(Cart.notes)}
            disabled={rol === "2" || rol === "3" ? false : true}
          >

          </textarea>

        </NotesContainer>

      </section>

      <section>

        <Button type="primary" id="btnConsultOrder" style={{ marginTop: "10px" }} onClick={(e) => update_order(e)}>Actualizar</Button>

      </section>


      {/* <section className="Form-Loading">
        <img src={Logo} alt="Santas Carnitas" />
        <p>{loading_status}</p>
      </section> */}

    </div>
  )
}